$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 100; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements(){
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }else {
                // $(this).removeClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * slider
	// *
	// *
	var $slider = $(".js-slider");

	if ($slider.length){
		$slider.each(function(){
			$(this).slick({
				fade: true,
				dots: false,
				infinite: true,
				arrows: false,
				autoplay: true,
				autoplaySpeed: 2000,
				speed: 1000,
                pauseOnHover: false,
                pauseOnFocus: false
			});
		});

    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * fullPage
	// *
	// *
    // hero animation variables
    var animationIsFinished = false;
    var videoWasTriggered = false;

    new fullpage('#fullpage', {
        anchors: ['page1', 'page2', 'page3', 'page4', 'page5', 'page6', 'page7', 'page8', 'page9'],
        scrollingSpeed: 400,
        // buy key!
        licenseKey: "gplv3-license",
        credits: false,

        // transform fullpage into normal site at breakpoints
        responsiveWidth: 700,
        responsiveHeight: 700,


        afterLoad: function( section, origin, destination, direction, trigger){

            // give active section triggered class once -> animation fires only 1 time
            $(".section.active").addClass("triggered");

            // trigger video play
            if(origin.index == 3 && videoWasTriggered == false) {
                setTimeout(function(){
                    $(".section .js-video").trigger('play');
                    videoWasTriggered = true;
                }, 800);
                return videoWasTriggered;


            }
    	},

        onLeave: function(origin, destination, direction, trigger){

            // hero animation
            if(origin.index == 0){

                // animation
                $(".js-hero").addClass("heroAnimation");

                // use flag animationIsFinished to determine if animation was fired
                if ($(window).width() > 700 && animationIsFinished == false)  {
                    setTimeout(function(){

                        animationIsFinished = true;

                        fullpage_api.silentMoveTo(destination.index + 1);

                    }, 900);
                    return animationIsFinished;
                } else {
                    return true;
                }
    		}

            // heroAnimation removes part of hero, restore rebuilds them
    		$(".js-hero").addClass("restore");
    	}
    });

});
